import React from "react"
import { graphql } from "gatsby"
import {Badge, Col, Container, Row} from "react-bootstrap"
import {IPage} from "../types";
import {GatsbyImage, StaticImage} from "gatsby-plugin-image";
import {Header} from "../components/Header";
import {ChildImageSharp} from "gatsby-theme-nieuwbouw/src/graphqlTypes";
import Page from "gatsby-theme-nieuwbouw/src/components/Page";
import {Project} from "../../../gatsby-theme-nieuwbouw/src/graphqlTypes";

interface Props {
    data: PageQueryData
}
export default ({ data }: Props) => {
    return (
        <Page pageTitle={data.markdownRemark.frontmatter.title} pageDescription={data.markdownRemark.frontmatter.description}>
            <Header image={data.desktopImage.childImageSharp.gatsbyImageData} />
            <Container
                fluid={'lg'}
                as={'section'}
                className={"my-5 text-center text-md-left"}
            >
                <Row>
                    <Col md={7}>
                        <div className={'pr-md-5'}>
                            <h2>
                                <span className={'bg-primary'}>Smaakvol</span> Kiezen
                            </h2>
                            <p className={'font-weight-bold'}>
                                Ga je voor een strakke of juist klassieke keuken? <br />
                                Een kookeiland of heel minimalistisch?
                            </p>
                            <p>
                                In de basis van de woningen is er bewust voor gekozen om geen keuken mee te nemen. De smaken hierin zijn zo divers dat we deze keuze geheel aan jou overlaten. Samen met de projectleverancier kan je jullie droomkeuken samenstellen. Zij zorgen ervoor dat jouw droomkeuken geheel naar wens wordt geplaatst.
                            </p>
                        </div>
                    </Col>
                    <Col md={5}>
                        <StaticImage
                            alt={"keuken"}
                            aspectRatio={474/304}
                            src={"../img/sfeer/christian-mackie-cc0Gg3BegjE-unsplash@2x.png"} />
                    </Col>
                </Row>
                <Row as={'section'} className={'my-5'}>
                    <Col
                        md={{span: 5, order: 2}}
                        className={'align-self-center'}
                    >
                        <div
                            className={'pl-md-5'}
                        >
                            <h2>
                                <span className={'bg-primary'}>Genieten</span> maar
                            </h2>
                            <p className={'font-weight-bold'}>
                                Heerlijk, even de boel de boel laten in de badkamer. Hoe gaat ‘ie eruit zien? Het toilet op de begane grond en de badkamer op de verdieping worden compleet opgeleverd met tegelwerk en sanitair.
                            </p>
                            <p>
                                Standaard is de badkamer voorzien van een vrijhangend toilet, wastafel, radiator en doucheopstelling. Houd je van uitgebreid badderen? Upgrade ‘m dan met een luxe ligbad voor extra ontspanningsmomenten.
                            </p>
                        </div>
                    </Col>
                    <Col md={7}>
                        <StaticImage
                            alt={"koken"}
                            aspectRatio={670/327}
                            src={"../img/sfeer/nieuw beeld genieten maar.jpg"} />
                    </Col>
                </Row>
                <Row as={'section'} className={'my-5'}>
                    <Col md={7}>
                        <div className={'pr-md-5'}>
                            <h2>
                                <span className={'bg-primary'}>Beschikbaarheid</span>
                            </h2>
                            <h6>Inschrijving en toewijzing</h6>
                            <p>
                                De genoemde prijzen zijn vrij op naam, dat wil zeggen inclusief o.a. grond-, kadaster-, makelaars-, architect- en notariskosten m.b.t. de transportakte en 21% BTW. Let op: eerder genoemde informatie over vanaf prijzen is niet het geval. <br />
                                Om aan te geven dat je een serieuze koper bent en deze woning ook kan financieren, vragen we naast de inschrijving om een bewijs van leencapaciteit van jullie financieel adviseur. Heb je die niet, dan kunnen wij je daarbij helpen. Kjeld van Bergen van Vergeer financieel Advies is bij ons aangesloten en helpt jullie graag. Kjeld is bereikbaar via 06- 42 15 41 59 of kjeld@vergeerfinancieeladvies.nl.
                            </p>
                        </div>
                    </Col>
                    <Col md={5}>
                        <div>
                            <StaticImage
                                alt={"badkamer"}
                                aspectRatio={474/319}
                                src={"../img/sfeer/chastity-cortijo-6TY_WrJTwSI-unsplash@2x.png"} />
                        </div>
                    </Col>
                </Row>
                {data.project.hoofdfoto &&
                <GatsbyImage
                    alt={"interieur"}
                    image={data.project.hoofdfoto.localFile.childImageSharp.gatsbyImageData} />
                }
            </Container>
        </Page>
    );
}

interface PageQueryData {
    markdownRemark: IPage
    desktopImage: ChildImageSharp
    project: Project
}

export const pageQuery = graphql`
    query {
        markdownRemark(frontmatter: {slug: {eq: "extra"}}) {
            ...Page
        }
        desktopImage: file(relativePath: {eq: "sfeer/nieuw beeld pagina locatie en hoofdbeeld situatie en extra.jpg"}) {
            childImageSharp {
                gatsbyImageData(
                    aspectRatio: 4
                    layout: FULL_WIDTH
                )
            }
        }
        project {
            hoofdfoto {
                localFile {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
            }
        }
    }
`
